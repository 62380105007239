<template>
  <div id="login" class="pb-5">
    <!-- SPINNER -->
    <transition name="fade">
      <div id="map-spinner" v-if="performingRequest">
        <RingLoader
          :color="'#333'"
        ></RingLoader>
      </div><!-- /#map-spinner -->
    </transition>

    <div class="login-form">
      <h1>Project Keebler</h1>
      <p class="lead">Build a forest kingdom!</p>

      <div :class="{ 'signup-form': !showLoginForm && !showForgotPassword }">

        <!-- Login Form -->
        <form v-if="showLoginForm" @submit.prevent>
          <div class="form-group">
            <label for="email1">Email</label>
            <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email1" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password1">Password</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" class="form-control" />
          </div><!-- /.form-group -->
          <button @click="login" class="btn btn-primary">Log In</button>
          <div class="extras">
            <button class="btn btn-sm btn-secondary mr-1" @click="togglePasswordReset">Forgot Password</button>
            <button class="btn btn-sm btn-secondary" @click="toggleForm">Create an Account</button>
          </div><!-- /.extras -->
        </form>

        <!-- Signup Form -->
        <form v-if="!showLoginForm && !showForgotPassword" @submit.prevent>
          <h2>Create an Account</h2>
          <div class="form-group">
            <label for="handle2">Player Handle</label>
            <input v-model.trim="signupForm.handle" type="text" placeholder="How others will see you" id="handle2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="email2">Email</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="you@email.com" id="email2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password2">Password</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="min 6 characters" id="password2" class="form-control" />
          </div><!-- /.form-group -->
          <button @click="signup" class="btn btn-primary">Sign Up</button>
          <div class="extras">
            <button class="btn btn-sm btn-secondary" @click="toggleForm">Back to Log In</button>
          </div><!-- /.extras -->
        </form>

        <!-- Forgot Password -->
        <form v-if="showForgotPassword" @submit.prevent class="password-reset">
          <div v-if="!passwordResetSuccess">
            <h2>Reset Password</h2>
            <p>We will send you an email to reset your password</p>
            <div class="form-group">
              <label for="email3">Email</label>
              <input v-model.trim="passwordForm.email" type="text" placeholder="you@email.com" id="email3" class="form-control" />
            </div><!-- /.form-control -->
            <button @click="resetPassword" class="btn btn-primary">Submit</button>
            <div class="extras">
              <button @click="togglePasswordReset" class="btn btn-sm btn-secondary">Back to Log In</button>
            </div><!-- /.extras -->
          </div><!-- /v-if=!passwordResetSuccess -->
          <div v-else>
            <h2>Email Sent</h2>
            <p>check your email for a link to reset your password</p>
            <button @click="togglePasswordReset" class="btn btn-primary">Back to login</button>
          </div><!-- /v-else -->
        </form>
      </div><!-- /.signup-form -->
    </div><!-- /.container -->
  </div><!-- /#login -->
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
const fb = require('../../firebaseConfig.js')

export default {
  components: { RingLoader },
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        email: '',
        password: '',
        handle: ''
      },
      passwordForm: {
        email: ''
      },
      showLoginForm: true,
      showForgotPassword: false,
      passwordResetSuccess: false,
      performingRequest: false,
      spinnerColor: '#61539b',
      spinnerSize: '50px'
    }
  },
  created () {
    if (this.$store.state.currentUser) {
      this.$router.push('/')
    }
    if (this.$route.params.mode === 'create') {
      this.showLoginForm = false
    }
    if (this.$route.params.mode === 'reset') {
      this.showLoginForm = false
      this.showForgotPassword = true
    }
  },
  methods: {
    toggleForm () {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset () {
      if (this.showForgotPassword) {
        this.showLoginForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
      } else {
        this.showLoginForm = false
        this.showForgotPassword = true
      }
    },
    login () {
      this.performingRequest = true
      fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
        this.$store.commit('setStateProperty', { property: 'currentUser', value: user.user })
        this.$store.dispatch('fetchUserProfile')
        this.performingRequest = false
        if (this.$route.params.redirect) {
          window.location.href = '/ts/' + this.$route.params.redirect
        } else {
          this.$router.push('/ts/')
        }
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    },
    signup () {
      this.performingRequest = true
      fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
        this.$store.commit('setStateProperty', { property: 'currentUser', value: user.user })
        fb.usersCollection.doc(user.user.uid).set({
          createdOn: new Date(),
          email: this.signupForm.email,
          handle: this.signupForm.handle
        }).then(() => {
          return fb.campaignsCollection.add({
            createdOn: new Date(),
            updatedOn: new Date(),
            ownerId: this.$store.state.currentUser.uid,
            nutSeeds: 1,
            fruitSeeds: 0,
            nuts: 0,
            fruit: 0,
            bread: 0,
            elixir: 0,
            gold: 0,
            level: 1,
            geoJsonPath: false,
            lastSeedCollected: new Date()
          })
        }).then(campaignDoc => {
          this.$store.dispatch('fetchUserProfile')
          this.performingRequest = false
          this.$router.push('/')
        }).catch(err => {
          console.log(err)
          this.performingRequest = false
          this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
        })
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    },
    resetPassword () {
      this.performingRequest = true
      fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
        this.performingRequest = false
        this.passwordResetSuccess = true
        this.passwordForm.email = ''
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    }
  }
}
</script>

<style scoped>
#map-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(255,255,255,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#login {
  padding: 20px;
  background-color: #eee;
  min-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-form {
  background-color: rgba(255,255,255,0.8);
  color: #333;
  max-width: 350px;
  margin: auto;
  padding: 30px 20px 30px 20px;
  border-radius: 20px;
}

.login-form .logo {
  width: 150px;
  margin-left: calc(50% - 75px);
  margin-bottom: 20px;
}

.login-form h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.login-form h2 {
  text-align: center;
  font-size: 24px;
}

.login-form p {
  text-align: center;
}

.login-form button.btn-primary {
  width: 100%;
  border-radius: 50px;
}

.extras {
  margin-top: 10px;
  text-align: center;
}

</style>
