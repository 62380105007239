<template>
  <div id="feature">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.feature.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'feature')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ feature.properties.name }}</h4>
      </template>
      <div v-if="exposeDetails">
        <p>Details</p>
        <div v-if="!closeEnough">
          <p>You are not close enough!</p>
        </div>
        <div v-if="closeEnough">
          <b-btn
            v-if="feature.properties.readyToCollect"
            @click="$store.dispatch('collect')"
          >Collect</b-btn>
        </div>
      </div>
    </b-card>
  </div><!-- /#feature -->
</template>

<script>
const turf = require('@turf/turf')

export default {
  data () {
    return {
      exposeDetails: false,
      closeEnough: true
    }
  },
  created () {
    this.flyToFeature()
    this.checkProximity()
  },
  computed: {
    feature: {
      get () {
        return this.$store.state.feature
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'feature', value: value })
      }
    }
  },
  methods: {
    checkProximity () {
      console.log(this.feature)
      console.log(this.$store.state.location)
      const to = turf.point(this.feature.geometry.coordinates)
      const from = turf.point([this.$store.state.location.coords.longitude, this.$store.state.location.coords.latitude])
      const options = { units: 'kilometers' }
      const distance = turf.distance(from, to, options)
      console.log(distance)
      // 30 meter distance requirement
      if (distance > 0.03) {
        this.closeEnough = false
      } else {
        this.closeEnough = true
      }
      this.exposeDetails = true
    },
    flyToFeature () {
      setTimeout(() => {
        this.$store.state.mapObject.flyTo({
          center: this.$store.state.feature.geometry.coordinates,
          animate: true
        })
      }, 310)
    }
  }
}
</script>
