<template>
  <div id="app">
    <router-view/>
    <div
      v-if="$store.state.spinner"
      class="spinner-container"
    >
      <Spinner
        :color="'#db832c'"
        id="spinner"
      />
    </div><!-- /.spinner-container -->
    <Alert
      v-if="$store.state.alert.active"
    />
  </div><!-- /#app -->
</template>

<script>
import Alert from './components/Alert'
import Spinner from 'vue-spinner/src/GridLoader'

export default {
  components: {
    Alert,
    Spinner
  }
}

</script>

<style scoped>
.spinner-container {
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active {
  transition: opacity 0s;
}

.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
