export default {
  updateFeaturesMarkers (state, value) {
    console.log('Update markers')
    // Remove any current markers
    for (var i = state.featuresMarkers.length - 1; i >= 0; i--) {
      state.featuresMarkers[i].remove()
    }
    state.featuresMarkers = []
    console.log(state.featuresGeoJson)

    state.featuresGeoJson.features.forEach(function (marker) {
      let readyToCollect = false
      const nowSeconds = new Date().getTime() / 1000
      const lastCollectedSeconds = marker.properties.lastCollected._seconds
      const secondsPast = nowSeconds - lastCollectedSeconds
      // 86400 is one day
      if (secondsPast > 43200 || marker.properties.type === 'building') {
        readyToCollect = true
      }
      var el = document.createElement('div')
      if (readyToCollect) {
        marker.properties.readyToCollect = true
        el.className = 'marker'
        if (marker.properties.type === 'tree') {
          const readyToCollectElement = document.createElement('div')
          readyToCollectElement.className = 'readyToCollect'
          el.appendChild(readyToCollectElement)
        }
      } else {
        marker.properties.readyToCollect = false
        el.className = 'marker'
      }
      el.style.backgroundImage = 'url(/art/' + marker.properties.machineName + '.png)'
      el.addEventListener('click', function (e) {
        // Prevent the `map.on('click')` from being triggered
        e.stopPropagation()
        state.feature = marker
        // Hide any drawers
        const allDrawers = Object.keys(state.drawer)
        allDrawers.forEach(drawer => {
          state.drawer[drawer].position = 'out'
          setTimeout(() => {
            state.drawer[drawer].show = false
          }, 300)
        })
        setTimeout(() => {
          // Show feature drawer
          state.drawer.feature.show = true
          setTimeout(() => {
            state.drawer.feature.position = 'in'
          }, 10)
          setTimeout(() => {
            const map = state.mapObject
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            if (vw >= 800) {
              map.easeTo({
                padding: { left: 500 },
                duration: 300
              })
            } else {
              const mapAdjustment = vh * 0.6
              map.easeTo({
                padding: { bottom: mapAdjustment },
                duration: 300
              })
            }
          }, 10)
        }, 320)
      })
      // add marker to map
      let newMarker = new mapboxgl.Marker(el) // eslint-disable-line
        .setLngLat(marker.geometry.coordinates) // eslint-disable-line
        .addTo(state.mapObject) // eslint-disable-line
      state.featuresMarkers.push(newMarker)
    })
  },
  setAlert (state, value) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  },
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  showDrawer (state, drawer) {
    state.drawer[drawer].show = true
    setTimeout(() => {
      state.drawer[drawer].position = 'in'
    }, 10)
    setTimeout(() => {
      const map = state.mapObject
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      if (vw >= 800) {
        map.easeTo({
          padding: { left: 500 },
          duration: 300
        })
      } else {
        const mapAdjustment = vh * 0.6
        map.easeTo({
          padding: { bottom: mapAdjustment },
          duration: 300
        })
      }
    }, 10)
  },
  hideDrawer (state, drawer) {
    state.drawer[drawer].position = 'out'
    const map = state.mapObject
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw >= 800) {
      map.easeTo({
        padding: { left: 0 },
        duration: 300
      })
    } else {
      map.easeTo({
        padding: { bottom: 0 },
        duration: 300
      })
    }
    setTimeout(() => {
      state.drawer[drawer].show = false
    }, 300)
  },
  hideAllDrawers (state) {
    const allDrawers = Object.keys(state.drawer)
    allDrawers.forEach(drawer => {
      state.drawer[drawer].position = 'out'
      setTimeout(() => {
        state.drawer[drawer].show = false
      }, 300)
    })
  }
}
