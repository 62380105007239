import axios from 'axios'
import firebase from 'firebase'
import KDBush from 'kdbush'
const fb = require('../firebaseConfig.js')
const turf = require('@turf/turf')
const geokdbush = require('geokdbush')

export default {
  build ({ commit, state }, item) {
    console.log('Build')
    console.log(item)
    const items = {
      hut: {
        costs: [
          {
            property: 'nuts',
            value: 10
          },
          {
            property: 'fruit',
            value: 10
          }
        ],
        name: 'Hut'
      }
    }
    let enoughResources = true
    items[item].costs.forEach(cost => {
      if (state.campaign[cost.property] < cost.value) {
        enoughResources = false
      }
    })

    if (!enoughResources) {
      commit('setAlert', { active: true, message: 'Not enough resources to build!', level: 'alert-warning', timeout: 8000 })
    } else {
      const currentLocation = new firebase.firestore.GeoPoint(state.location.coords.latitude, state.location.coords.longitude)
      fb.featuresCollection.add({
        createdOn: new Date(),
        createdBy: state.currentUser.uid,
        updatedOn: new Date(),
        lastCollected: new Date(),
        geolocation: currentLocation,
        campaignId: state.campaign.id,
        type: 'building',
        category: 'hut',
        machineName: item,
        name: items[item].name
      }).then(() => {
        console.log('Deduct seeds and harvest from tree')
        const campaignData = state.campaign
        items[item].costs.forEach(cost => {
          campaignData[cost.property] = campaignData[cost.property] - cost.value
        })

        // campaignData[items[item].property] = campaignData[items[item].property] - items[item].cost
        return fb.campaignsCollection.doc(state.campaign.id).update({
          updatedOn: new Date(),
          nutSeeds: campaignData.nutSeeds,
          fruitSeeds: campaignData.fruitSeeds,
          nuts: campaignData.nuts,
          fruit: campaignData.fruit,
          bread: campaignData.bread,
          elixir: campaignData.elixir,
          gold: campaignData.gold
        })
      }).then(() => {
        console.log('Compiling...')
        return fb.compileRequestsCollection.add({
          createdOn: new Date(),
          campaignId: state.campaign.id,
          oldGeoJsonPath: state.campaign.geoJsonPath
        })
      }).catch(err => {
        console.log(err.message)
      })
    }
  },
  collectSeed ({ commit, state }) {
    console.log('Collecting seed')
    const seedTypes = [
      {
        name: 'Nut Seed',
        value: 'nutSeeds'
      },
      {
        name: 'Fruit Seed',
        value: 'fruitSeeds'
      }
    ]
    const newSeed = seedTypes[Math.floor(Math.random() * seedTypes.length)]
    const campaignData = state.campaign
    campaignData[newSeed.value]++
    console.log(campaignData)
    return fb.campaignsCollection.doc(state.campaign.id).update({
      nutSeeds: campaignData.nutSeeds,
      fruitSeeds: campaignData.fruitSeeds,
      lastSeedCollected: new Date()
    }).then(() => {
      commit('setAlert', {
        active: true,
        message: 'Congrats! You got a ' + newSeed.name + '!!',
        level: 'alert-success',
        timeout: 8000
      })
    }).catch(err => {
      console.log(err.message)
    })
  },
  collect ({ commit, state }) {
    console.log('Collect resources. Updating campaign')
    commit('setStateProperty', { property: 'collecting', value: true })
    const campaignData = state.campaign
    async function updateTree (treeId) {
      await fb.featuresCollection.doc(treeId).update({
        updatedOn: new Date(),
        updatedBy: state.currentUser.uid,
        lastCollected: new Date()
      }).then(() => {
        return true
      })
    }
    // INDIVIDUAL TREES
    if (state.feature.properties.type === 'tree') {
      campaignData[state.feature.properties.category]++
      return fb.campaignsCollection.doc(state.campaign.id).update({
        updatedOn: new Date(),
        nutSeeds: campaignData.nutSeeds,
        fruitSeeds: campaignData.fruitSeeds,
        nuts: campaignData.nuts,
        fruit: campaignData.fruit,
        bread: campaignData.bread,
        elixir: campaignData.elixir,
        gold: campaignData.gold
      }).then(() => {
        console.log('Updating the feature')
        return fb.featuresCollection.doc(state.feature.properties.featureId).update({
          updatedOn: new Date(),
          updatedBy: state.currentUser.uid,
          lastCollected: new Date()
        })
      }).then(() => {
        commit('hideAllDrawers')
        commit('setStateProperty', { property: 'collecting', value: false })
        // Compile Request
        console.log('Compiling...')
        return fb.compileRequestsCollection.add({
          createdOn: new Date(),
          campaignId: state.campaign.id,
          oldGeoJsonPath: state.campaign.geoJsonPath
        })
      }).catch(err => {
        console.log(err.message)
      })
    } else if (state.feature.properties.type === 'building') {
      console.log('This is a building')
      // BUILDINGS
      if (state.feature.properties.category === 'hut') {
        console.log('This is a hut')
        console.log(state.featuresGeoJson)
        console.log(state.location)
        var featuresIndex = new KDBush(state.featuresGeoJson.features, (p) => p.geometry.coordinates[0], (p) => p.geometry.coordinates[1]) // eslint-disable-line
        var neighborhoodFeatures = geokdbush.around(featuresIndex, state.location.coords.longitude, state.location.coords.latitude, Infinity, 0.25)
        var neighborhoodFeaturesCollection = {
          type: 'FeatureCollection',
          features: neighborhoodFeatures
        }
        console.log(neighborhoodFeaturesCollection)
        const promises = []
        const newResources = {
          nuts: 0,
          fruit: 0
        }
        neighborhoodFeaturesCollection.features.forEach(feature => {
          if (feature.properties.type === 'tree' && feature.properties.readyToCollect) {
            campaignData[feature.properties.category]++
            newResources[feature.properties.category]++
            const prom = updateTree(feature.properties.featureId)
            promises.push(prom)
          }
        })
        commit('setAlert', { active: true, message: 'Collected ' + newResources.nuts + ' nuts and ' + newResources.fruit + ' fruit!', level: 'alert-success', timeout: 8000 })
        Promise.all(promises).then(() => {
          return fb.campaignsCollection.doc(state.campaign.id).update({
            updatedOn: new Date(),
            updatedBy: state.currentUser.uid,
            nutSeeds: campaignData.nutSeeds,
            fruitSeeds: campaignData.fruitSeeds,
            nuts: campaignData.nuts,
            fruit: campaignData.fruit,
            bread: campaignData.bread,
            elixir: campaignData.elixir,
            gold: campaignData.gold
          })
        }).then(() => {
          commit('hideAllDrawers')
          commit('setStateProperty', { property: 'collecting', value: false })
          // Compile Request
          console.log('Compiling...')
          return fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: state.currentUser.uid,
            campaignId: state.campaign.id,
            oldGeoJsonPath: state.campaign.geoJsonPath
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
    }
  },
  mapFeatures ({ commit, state }) {
    console.log('Map Features')
    const geoJsonPath = state.campaign.geoJsonPath
    const geoJsonStorageRef = firebase.storage().ref(geoJsonPath)
    geoJsonStorageRef.getDownloadURL().then(geoJsonUrl => {
      return axios.get(geoJsonUrl)
    }).then(response => {
      commit('setStateProperty', { property: 'featuresGeoJson', value: response.data })
      commit('updateFeaturesMarkers')
    })
  },
  plant ({ commit, state }, seedFrom) {
    console.log('Plant')
    console.log(state.campaign[seedFrom])
    let randomTree
    const currentPosition = turf.point([state.location.coords.longitude, state.location.coords.latitude])
    const treesGeoJson = {
      type: 'FeatureCollection',
      features: []
    }
    let distance = 1
    if (state.featuresGeoJson.features) {
      state.featuresGeoJson.features.forEach(feature => {
        if (feature.properties.type === 'tree') {
          treesGeoJson.features.push(feature)
        }
      })
      if (treesGeoJson.features.length > 0) {
        const nearestTree = turf.nearestPoint(currentPosition, treesGeoJson)
        const to = turf.point(nearestTree.geometry.coordinates)
        const options = { units: 'kilometers' }
        distance = turf.distance(currentPosition, to, options)
      }
    }
    console.log(distance)
    if (state.campaign[seedFrom] < 1) {
      commit('setAlert', { active: true, message: 'None of these seeds available!', level: 'alert-warning', timeout: 8000 })
    } else if (distance < 0.03) {
      commit('setAlert', { active: true, message: 'Too close to other trees! Move further away.', level: 'alert-warning', timeout: 8000 })
    } else {
      // Pick a tree
      // Get trees from my level in the selected seed category
      let treeCategory
      if (seedFrom === 'nutSeeds') {
        treeCategory = 'nuts'
      } else if (seedFrom === 'fruitSeeds') {
        treeCategory = 'fruit'
      }
      fb.treesCollection.where('level', '<=', state.campaign.level).where('category', '==', treeCategory).get().then(trees => {
        const availableTrees = []
        trees.forEach(tree => {
          const treeItem = tree.data()
          treeItem.id = tree.id
          availableTrees.push(treeItem)
        })
        // Pick a random tree
        randomTree = availableTrees[Math.floor(Math.random() * availableTrees.length)]
        const currentLocation = new firebase.firestore.GeoPoint(state.location.coords.latitude, state.location.coords.longitude)
        // Plant the tree
        return fb.featuresCollection.add({
          createdOn: new Date(),
          createdBy: state.currentUser.uid,
          updatedOn: new Date(),
          lastCollected: new Date(),
          geolocation: currentLocation,
          campaignId: state.campaign.id,
          type: 'tree',
          category: randomTree.category,
          level: randomTree.level,
          machineName: randomTree.machineName,
          name: randomTree.name
        })
      }).then(() => {
        console.log('Deduct seeds and harvest from tree')
        const campaignData = state.campaign
        campaignData[seedFrom]--
        campaignData[randomTree.category]++
        return fb.campaignsCollection.doc(state.campaign.id).update({
          updatedOn: new Date(),
          nutSeeds: campaignData.nutSeeds,
          fruitSeeds: campaignData.fruitSeeds,
          nuts: campaignData.nuts,
          fruit: campaignData.fruit,
          bread: campaignData.bread,
          elixir: campaignData.elixir,
          gold: campaignData.gold
        })
      }).then(() => {
        console.log('Compiling...')
        return fb.compileRequestsCollection.add({
          createdOn: new Date(),
          campaignId: state.campaign.id,
          oldGeoJsonPath: state.campaign.geoJsonPath
        })
      }).catch(err => {
        console.log(err.message)
      })
    }
  },
  centerMap ({ commit, state }) {
    state.mapObject.flyTo({
      center: [
        state.location.coords.longitude,
        state.location.coords.latitude
      ],
      zoom: 17,
      animate: true
    })
  },
  getLocation ({ commit, state }) {
    console.log('Get location')
    commit('setStateProperty', { property: 'locationWarning', value: false })
    function gotLocation (position) {
      console.log(position)
      if (position.coords.accuracy > 30) {
        commit('setAlert', { active: true, message: 'Bad GPS Signal. Please wait...', level: 'alert-danger', timeout: 5000 })
        if (!state.initialLoad) {
          setTimeout(function () {
            console.log('Had bad GPS... checking it out.')
            if (state.location.coords.accuracy > 30) {
              location.reload()
            } else {
              console.log('GPS better now.')
            }
          }, 5000)
        } else {
          console.log('Bad GPS on initial load. Might be SOL')
        }
      }
      commit('setStateProperty', { property: 'location', value: position })
      if (state.initialLoad) {
        state.mapObject.flyTo({
          center: [
            position.coords.longitude,
            position.coords.latitude
          ],
          zoom: 17,
          animate: false
        })
        commit('setStateProperty', { property: 'initialLoad', value: false })
      }
      const characterGeoJson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [
              position.coords.longitude,
              position.coords.latitude
            ]
          }
        }]
      }
      if (state.mapObject.getLayer('character')) {
        state.mapObject.removeLayer('character')
        state.mapObject.removeSource('character')
      }
      state.mapObject.addLayer({
        id: 'character',
        type: 'circle',
        source: {
          type: 'geojson',
          data: characterGeoJson
        },
        layout: {},
        paint: {
          'circle-radius': 6,
          'circle-color': 'blue'
        }
      })
      commit('setStateProperty', { property: 'spinner', value: false })
    }
    function errorGettingLocation (error) {
      console.log(error)
      commit('setStateProperty', { property: 'locationWarning', value: 'Having trouble getting Geolocation' })
    }
    const geoOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
    navigator.geolocation.watchPosition(gotLocation, errorGettingLocation, geoOptions)
  },
  initCampaignMap ({ commit, state, dispatch }) {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib3JiaXRpc3QiLCJhIjoiYnpUTnJBdyJ9.uxgaJ0R9ZNsCcEnPNfo2ag' // eslint-disable-line

    state.mapObject = new mapboxgl.Map({ // eslint-disable-line
      container: 'campaignMap',
      style: 'mapbox://styles/orbitist/cklxzs5bs24d917ndl4jnh4td',
      center: [-95, 40],
      zoom: 3
    })

    state.mapObject.on('load', function () {
      dispatch('getLocation')
    }) // map.on load
  },
  initCampaign ({ commit, state, dispatch }) {
    console.log('Init Campaign')
    commit('setStateProperty', { property: 'spinner', value: true })
    dispatch('initCampaignMap')
    fb.campaignsCollection.where('ownerId', '==', state.currentUser.uid).orderBy('updatedOn', 'desc').limit(1).onSnapshot(campaigns => {
      let campaign
      campaigns.forEach((item, i) => {
        campaign = item.data()
        campaign.id = item.id
      })
      console.log('Got campaign data')
      commit('setStateProperty', { property: 'campaign', value: campaign })
      console.log(state.campaign)
      const nowSeconds = new Date().getTime() / 1000
      const lastSeedCollectedAgo = nowSeconds - state.campaign.lastSeedCollected.seconds
      // 8 Hrs between seeds
      // if (lastSeedCollectedAgo > 28800) {
      if (lastSeedCollectedAgo > 1800) { // 30 minutes (for testing)
        commit('setStateProperty', { property: 'newSeedAvailable', value: true })
      } else {
        commit('setStateProperty', { property: 'newSeedAvailable', value: false })
      }
      if (state.campaign.geoJsonPath) {
        dispatch('mapFeatures')
      }
    })
  },
  fetchUserProfile ({ commit, state }) {
    fb.usersCollection.doc(state.currentUser.uid).onSnapshot(res => {
      commit('setStateProperty', { property: 'userProfile', value: res.data() })
    })
  }
}
