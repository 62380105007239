import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyCb_VReJi8656l2ksTvlB1pnfpIzSMllRw',
  authDomain: 'project-keebler.firebaseapp.com',
  projectId: 'project-keebler',
  storageBucket: 'project-keebler.appspot.com',
  messagingSenderId: '608391441422',
  appId: '1:608391441422:web:ffb0f3e1473a0aaf36a05f',
  measurementId: 'G-6XZ8P0Y70K'
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()
const currentUser = auth.currentUser

// date issue fix according to firebase
const settings = {
}
db.settings(settings)

// firebase collections
const usersCollection = db.collection('users')
const campaignsCollection = db.collection('campaigns')
const treesCollection = db.collection('trees')
const featuresCollection = db.collection('features')
const compileRequestsCollection = db.collection('compileRequests')

export {
  db,
  storage,
  auth,
  currentUser,
  usersCollection,
  campaignsCollection,
  treesCollection,
  featuresCollection,
  compileRequestsCollection
}
