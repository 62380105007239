<template>
  <div id="trailSystemDetails">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.settings.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'settings')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Settings</h4>
      </template>
      <b-btn @click="logout()">Log out</b-btn>
    </b-card>
  </div><!-- /#trail -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  methods: {
    logout () {
      fb.auth.signOut().then(() => {
        this.$router.push('/login')
        location.reload()
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    }
  }
}
</script>
