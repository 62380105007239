<template>
  <div id="campaign_map">
    <div id="campaignMap"></div>
  </div><!-- /#campaign_map -->
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('initCampaign')
  }
}
</script>

<style scoped>
#campaignMap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
</style>
