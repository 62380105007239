import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
const fb = require('../firebaseConfig.js')

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setStateProperty', { property: 'currentUser', value: user })
    store.dispatch('fetchUserProfile')
    fb.usersCollection.doc(user.uid).onSnapshot(doc => {
      store.commit('setStateProperty', { property: 'userProfile', value: doc.data() })
    })
  }
})

export const store = new Vuex.Store({
  state: {
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    collecting: false,
    currentUser: false,
    campaign: false,
    drawer: {
      build: {
        show: false,
        position: 'out'
      },
      feature: {
        show: false,
        position: 'out'
      },
      plant: {
        show: false,
        position: 'out'
      },
      settings: {
        show: false,
        position: 'out'
      }
    },
    feature: {},
    featuresGeoJson: {},
    featuresMarkers: [],
    initialLoad: true,
    location: false,
    locationWarning: false,
    mapObject: {},
    newSeedAvailable: false,
    spinner: false,
    userProfile: false
  },
  actions: actions,
  mutations: mutations
})
