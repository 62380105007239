<template>
  <div id="home">
    <CampaignMap />
    <CampaignMapMenu />
    <Feature v-if="$store.state.drawer.feature.show" />
    <LocationPrompt v-if="$store.state.locationWarning" />
    <Build v-if="$store.state.drawer.build.show" />
    <Plant v-if="$store.state.drawer.plant.show" />
    <Settings v-if="$store.state.drawer.settings.show" />
  </div><!-- /#home -->
</template>

<script>
import Build from './drawers/Build'
import CampaignMap from './CampaignMap'
import CampaignMapMenu from './CampaignMapMenu'
import Feature from './drawers/Feature'
import LocationPrompt from './LocationPrompt'
import Plant from './drawers/Plant'
import Settings from './drawers/Settings'

export default {
  components: {
    Build,
    CampaignMap,
    CampaignMapMenu,
    Feature,
    LocationPrompt,
    Plant,
    Settings
  }
}
</script>
